<template>
  <div v-frag>
    <v-container fluid>
      <v-alert
        v-model='alertOptions.show'
        :color='alertOptions.color'
        class='rounded-xl'
        dark
        dense
        dismissible
        icon='mdi-check-circle-outline'
        prominent
        transition='scale-transition'
        width='100%'
      >
        <h3 class='mt-2'>{{ alertOptions.title }}</h3>
        <p>{{ alertOptions.body }}</p>
      </v-alert>

      <v-row align='center' align-content='center' justify='center'>
        <v-col cols='12' lg='12' md='12' sm='12' xl='12' xs='12'>
          <v-card class='rounded-lg' elevation='3'>
            <v-card-title class='grey--text mb-2'>
              <v-row>
                <v-col class='d-flex flex-column mb-6' lg='3' md='3' sm='12'>
                  <v-row>
                    <v-col>
                      <v-card class='avatarCharity'>
                        <v-avatar color='grey' size='110'>
                          <v-img :src='topDesserts[0].logo'></v-img>
                        </v-avatar>
                        <div class='d-flex flex-column mb-6 progressBar'>
                          <v-row>
                            <span class='percentage'>{{ percentage }}%</span>
                          </v-row>
                          <v-row class='progressLinaer'>
                            <v-progress-linear
                              :value='percentage'
                              height='15'
                              rounded
                            ></v-progress-linear>
                          </v-row>
                          <v-row class='currencies'>
                            <span>$0,00</span>
                            <span>${{ topDesserts[0].mount_required }}</span>
                          </v-row>
                        </div>
                      </v-card>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-btn
                        :disabled='ifUser()'
                        block
                        class='white--text text-capitalize editNeedButton'
                        color='blue'
                        height='100%'
                        outlined
                        @click='openDialog'
                      >
                        Edit Need
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col lg='9' md='9' sm='12'>
                  <v-data-table
                    :headers='topHeaders'
                    :items='topDesserts'
                    :items-per-page='5'
                    class='elevation-3 topDataTable'
                    hide-default-footer
                  >
                    <template v-slot:[`item.mount_required`]='{ item }'>
                      <span> {{ formatMoney(item.mount_required) }} </span>
                    </template>

                    <template v-slot:[`item.total_donations`]='{ item }'>
                      <span> {{ formatMoney(item.total_donations) }} </span>
                    </template>

                    <template v-slot:[`item.end`]='{ item }'>
                      <span> {{ item.end }} </span>
                    </template>
                  </v-data-table>
                  <v-simple-table class='elevation-3 descriptionText' dense>
                    <template v-slot:default>
                      <thead>
                      <tr>
                        <th class='text-left'>Description</th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr>
                        <td>
                          {{ topDesserts[0].description }}
                        </td>
                      </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-col>
              </v-row>
            </v-card-title>
          </v-card>
        </v-col>
        <v-col cols='12' lg='12' md='12' sm='12' xl='12' xs='12'>
          <v-card class='rounded-lg' elevation='3'>
            <v-card-title class=''>
              <v-row>
                <v-col cols='12' lg='6' md='6' sm='12' xl='6' xs='12'>
                  Donations transaction history
                </v-col>

                <v-col cols='12' lg='6' md='6' sm='12' xl='6' xs='12'>
                  <v-text-field
                    v-model='search'
                    class='rounded-lg'
                    dense
                    filled
                    hide-details
                    placeholder='Search'
                    prepend-inner-icon='mdi-magnify'
                    rounded
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-card-title>

            <v-container fluid>
              <v-data-table
                :headers='headers'
                :items='desserts'
                :loading='loading'
                :search='search'
                class='elevation-1'
                hide-default-footer
                item-key='id'
              >
                <template v-slot:[`item.need`]='{ item }'>
                  <span class='eventNeed' @click='clickEvent(item)'>{{
                      item.need
                                                                    }}</span>
                </template>

                <template v-slot:[`item.date`]='{ item }'>
                  <span> {{ formatToDate(item.date) }} </span>
                </template>

                <template v-slot:[`item.donation_amount`]='{ item }'>
                  <span> {{ formatMoney(item.donation_amount) }} </span>
                </template>

                <template v-slot:no-data>
                  <v-btn color='primary' @click='initialize'> Reset</v-btn>
                </template>
              </v-data-table>
            </v-container>
          </v-card>
        </v-col>
      </v-row>

      <v-dialog v-model='dialog' max-width='1000px' persistent scrollable>
        <v-card>
          <v-card-title>
            <span class='headline'>Edit Need</span>
          </v-card-title>

          <v-card-text class='py-2 px-4'>
            <v-container>
              <v-form ref='form' v-model='valid' class='modalForm'>
                <v-row class='imageRow'>
                  <v-col>
                    <v-avatar color='grey' size='100'>
                      <v-img :src='image'></v-img>
                    </v-avatar>
                  </v-col>
                  <v-col md='6' sm='6'>
                    <v-file-input
                      v-model='fileSelected'
                      accept='image/png, image/jpeg, image/bmp'
                      chips
                      dense
                      label='Event Avatar'
                      outlined
                      placeholder='Add a file or drop it here'
                      prepend-icon='mdi-camera'
                      show-size
                      @change='selectFile'
                    ></v-file-input>
                  </v-col>
                </v-row>
                <v-row md='4' sm='6'>
                  <v-col>
                    <p class='font-weight-bold label'>Need</p>
                    <v-text-field
                      ref='charityNameInput'
                      v-model='editedItem.need'
                      :rules='nameRules'
                      outlined
                      required
                    ></v-text-field>
                  </v-col>
                  <v-col>
                    <p class='font-weight-bold label'>Funds Needed</p>
                    <v-currency-field
                      v-model='editedItem.mount_required'
                      outlined
                      prefix='$'
                    />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col md='6' sm='12'>
                    <v-menu
                      v-model='menuDateStart'
                      :close-on-content-click='false'
                      :nudge-right='40'
                      min-width='auto'
                      offset-y
                      transition='scale-transition'
                    >
                      <template v-slot:activator='{ on, attrs }'>
                        <p class='font-weight-bold label'>Start</p>
                        <v-text-field
                          v-model='editedItem.start'
                          prepend-icon='mdi-calendar'
                          readonly
                          v-bind='attrs'
                          v-on='on'
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model='editedItem.start'
                        @input='menuDateStart = false'
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col>
                    <v-menu
                      v-model='menuDateDeadline'
                      :close-on-content-click='false'
                      :nudge-right='40'
                      min-width='auto'
                      offset-y
                      transition='scale-transition'
                    >
                      <template v-slot:activator='{ on, attrs }'>
                        <p class='font-weight-bold label'>Deadline</p>
                        <v-text-field
                          v-model='editedItem.end'
                          prepend-icon='mdi-calendar'
                          readonly
                          v-bind='attrs'
                          v-on='on'
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model='editedItem.end'
                        @input='menuDateDeadline = false'
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <p class='font-weight-bold label'>Description</p>
                    <v-textarea
                      v-model='editedItem.description'
                      outlined
                      rows='3'
                    ></v-textarea>
                  </v-col>
                </v-row>
              </v-form>
            </v-container>
          </v-card-text>
          <v-card-actions class='d-flex direction-row justify-center'>
            <v-btn
              class='px-14 ma-4 font-weight-bold'
              color='blue'
              large
              outlined
              @click='closeDialog'
            >
              Cancel
            </v-btn>
            <v-btn
              class='px-14 ma-4 font-weight-bold white--text'
              color='primary'
              large
              @click='saveEvent'
            >
              Submit
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-container>
  </div>
</template>

<script>
import donationsService from '../../services/donations/index'

export default {
  data() {
    return {
      search: '',
      loading: false,
      totalDesserts: 5,
      options: {},
      dialog: false,
      alertOptions: {
        show: false
      },
      menuDateStart: false,
      menuDateDeadline: false,
      percentage: 60,
      valid: true,
      topHeaders: [
        {text: 'Need', align: 'start', value: 'need', divider: true},
        {text: 'Funds Needed', value: 'mount_required', divider: true},
        {text: 'Amount Raised', value: 'total_donations', divider: true},
        {text: 'Deadline', value: 'end'}
      ],
      headers: [
        {text: 'Date', align: 'start', value: 'date', divider: true},
        {text: 'Donators', value: 'employee_name', divider: true},
        {text: 'Company', value: 'member_name', divider: true},
        {text: 'Amount', value: 'donation_amount', divider: true}
      ],
      topDesserts: [],
      desserts: [],
      editedItem: {},
      editedInde: -1,
      fileSelected: null,
      changeFile: false,
      image: '',
      nameRules: [
        (v) => !!v || 'Name is required',
        (v) => (v && v.length <= 25) || 'Name must be less than 25 characters'
      ]
    }
  },
  methods: {
    initialize() {
      const charityEvent = {...this.$store.getters.charityEventDetail}
      this.topDesserts = []
      this.percentage = Math.round(
        (charityEvent.total_donations * 100) / charityEvent.mount_required
      )
      this.image = charityEvent.logo
      this.topDesserts.push(charityEvent)
      this.desserts = charityEvent.charity_requests
    },
    saveEvent() {
      const data = {
        charityEventId: this.editedItem.id,
        need: this.editedItem.need,
        mount_required: Number(this.editedItem.mount_required),
        start: this.editedItem.start,
        end: this.editedItem.end,
        description: this.editedItem.description
      }
      if (this.changeFile) {
        data.logo = this.fileSelected
      }
      donationsService
        .updateEventDonation(data)
        .then((res) => {
          res.mount_required = Number(res.mount_required)
          this.topDesserts[0] = res
          this.closeDialog()
          this.openAlert('updated')
        })
        .catch(() => {
          this.closeDialog()
          this.openAlert('error')
        })
    },
    selectFile(file) {
      if (file) {
        this.changeFile = true
        this.fileSelected = file
        this.image = URL.createObjectURL(file)
      } else {
        this.fileSelected.image = null
        this.image = ''
      }
    },
    ifUser() {
      return this.$store.getters.user.rol !== 'Charity'
    },
    openDialog() {
      this.fileSelected = this.formatAvatar(this.topDesserts[0].logo)
      this.editedItem = this.topDesserts[0]
      this.dialog = true
    },
    closeDialog() {
      this.editedItem = {}
      this.dialog = !this.dialog
      this.changeFile = false
    },
    openAlert(operation) {
      const options = {
        show: true,
        color: 'success',
        title: '',
        body: ''
      }
      switch (operation) {
        case 'updated':
          options.title = 'Event updated!'
          options.body = 'Event was successfully updated.'
          break
        case 'error':
          options.color = 'error'
          options.title = 'Something went wrong!'
          options.body = 'Something has gone wrong, please try again.'
          break
        default:
          break
      }
      this.alertOptions = options
    },
    formatMoney: function(value) {
      return value.toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD'
      })
    },
    formatToDate: (value) => {
      if (value) {
        const date = new Date(value)
        return `${date.getFullYear()}/${date.getMonth() + 1}/${date.getDate()}`
      }
    },
    formatAvatar(value) {
      if (typeof value == 'string' && value != '') {
        let fileName = value.split('/')
        fileName = fileName[fileName.length - 1]
        return new File([`${fileName}`], `${fileName}`, {
          type: 'text/plain'
        })
      } else {
        return ''
      }
    }
  },
  created() {
    this.initialize()
  }
}
</script>

<style scoped>
.avatarCharity {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1rem;
}
.avatarCharity .v-avatar {
  margin-bottom: 1.5rem;
  border-radius: 100% !important;
}
@media (min-width: 960px) and (max-width: 1024px) {
  .avatarCharity .v-avatar,
  .avatarCharity .v-avatar * {
    width: 80px !important;
    height: 80px !important;
    min-width: 80px !important;
  }
}
.topDataTable {
  margin-bottom: 1.5rem;
}
.editNeedButton {
  width: 80px !important;
  height: 40px !important;
}
.descriptionText td {
  display: block;
  height: auto !important;
  padding: 16px !important;
  min-height: 90px !important;
  font-size: 14px;
  color: #666;
  font-weight: 400;
  line-height: 1.2;
}
@media (max-width: 991px) {
  .descriptionText td {
    min-height: inherit !important;
  }
}
@media (max-width: 1177px) {
  .progressBar {
    display: flex;
    flex-direction: row;
  }
}
.progressBar {
  align-items: center;
  margin-bottom: 0px !important;
  width: 100%;
}
.progressBar .v-progress-linear {
  margin: 8px 0;
}
.progressLinaer {
  width: 100%;
}
.progressBar .percentage {
  color: #1e88e5;
}
.progressBar .currencies {
  font-weight: 280;
  font-size: small;
  display: flex;
  justify-content: space-between;
  margin-top: 0;
  width: 100%;
}
.modalForm .label {
  margin-bottom: 1px;
}
.modalForm .v-input__slot {
  min-height: 42px !important;
}
.modalForm .col {
  padding-top: 0;
  padding-bottom: 0;
}
.headline {
  margin-bottom: -10px;
  padding-bottom: 0px;
}
.v-input__control {
  margin-bottom: -8px;
}
.imageRow {
  display: flex;
  align-items: center;
  padding-bottom: 12px;
}
</style>
