import {HTTP} from '../../../http-common'
import helper from '../index'

export default {
  getDonations: async() => {
    const res = await HTTP.get(`/donations`, {
      headers: helper.setHeaders()
    })
    return res.data.data
  },
  getAllDonations: async() => {
    const res = await HTTP.get(`donation-opportunities`, {
      headers: helper.setHeaders()
    })
    return res.data.data
  },
  updateEventDonation: async({
    charityEventId,
    logo,
    need,
    mount_required,
    start,
    end,
    description
  }) => {
    let formData = new FormData()
    let headers = helper.setHeaders()
    headers['Content-Type'] = 'multipart/form-data'
    formData.append('need', need)
    formData.append('mount_required', mount_required)
    formData.append('start', start)
    formData.append('end', end)
    formData.append('description', description)
    formData.append('logo', logo)
    formData.append('_method', 'PUT')
    const response = await HTTP.post(`/donations/${charityEventId}`, formData, {
      headers: headers
    })
    return response.data.data
  },
  createEvent: async({
    logo,
    need,
    mount_required,
    start,
    end,
    description,
    fileType
  }) => {
    let formData = new FormData()
    let headers = helper.setHeaders()
    headers['Content-Type'] = 'multipart/form-data'
    formData.append('need', need)
    formData.append('mount_required', mount_required)
    formData.append('start', start)
    formData.append('end', end)
    formData.append('description', description)
    if (logo) formData.append('logo', logo, {type: fileType})
    const response = await HTTP.post('/donations', formData, {
      headers: headers
    })
    return response.data.data
  },
  deleteEvent: async(id) => {
    const headers = helper.setHeaders()
    const res = await HTTP.delete(`/donations/${id}`, {
      headers
    })
    return res
  },
  uploadLogo: async(logo, charityEventId) => {
    const res = await HTTP.post(
      `/charity-events/upload/${charityEventId}`,
      logo
    )
    return res
  },
  showDonations: async(charityId) => {
    const res = await HTTP.get(`/donations/${charityId}`, {
      headers: helper.setHeaders()
    })
    return res.data.data
  },
  donate: async(data) => {
    try {
      const res = await HTTP.post('/stripe-charge', data, {
        headers: helper.setHeaders()
      })
      return res.data
    } catch (e) {
      return e.response
    }
  },
  suscription: async(data) => {
    try {
      const res = await HTTP.post('/stripe-suscription-operation', data, {
        headers: helper.setHeaders()
      })
      return res.data
    } catch (e) {
      return e.response
    }
  },
  createDonationRequest: async(data) => {
    await HTTP.post('/charity-requests', data, {
      headers: helper.setHeaders()
    })
  },
  cancelSubscription: async(data) => {
    const res = await HTTP.post('/stripe-suscription-cancel-by-user', data, {
      headers: helper.setHeaders()
    })
    return res.data
  }
}
